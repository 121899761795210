@import "scss/base.scss";

.labels {
   width: 12rem;
   display: inline-block;
}

.field {
    margin-right: $margin-small;
}
