@import "scss/base.scss";

.dropdownButton {
  background-color: transparent;
  appearance: none;
  border: none;
  font-size: $input-font-size;
  font-weight: bold;
}

.dropdown {
  right: 0;
  min-width: 100px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: $color-white;
  min-width: 150px;
}

.dropdownContent a {
  color: $color-black;
  padding: $padding-medium;
  display: block;
}

.dropdownContent a:hover {
  background-color: #d1d1d1;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: rgb(0, 105, 0);
}

.dropdownArrow {
  width: 15px;
  margin-left: $padding-medium;

  path {
    fill: $color-black;
  }
}
