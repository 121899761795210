@import 'scss/base.scss';

.header {
    background-color: $color-background-footer;
    display: flex; 
    align-items: center;
}

.headerLogo {
    height: 3rem;

    :global(.leaf) {
        fill: white;
    }
}

.nav {
  display: none;
  margin-left: auto;
  margin-right: $margin-medium;
  right: 0;
}

.healthChecks {
    display: flex; 
    margin-left: 225px;
    justify-content: space-evenly;
    width: 40%;
}

.dropdownButton {
  background-color: rgb(0, 161, 0);
  color: white;
  padding: 16px;
  font-size: 16px;
}

.dropdown {
  margin-left: auto;
  margin-right: $margin-medium;
  right: 0;
  min-width: 100px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: rgb(192, 192, 192);
  min-width: 150px;
}

.dropdownContent a {
  color: black;
  padding: 16px;
  display: block;
}

.dropdownContent a:hover 
{
  background-color: rgb(131, 131, 131);
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: rgb(0, 105, 0);
}