$padding-small: 0.5rem;
$padding-medium: 0.75rem;
$padding-large: 1rem;

$radius-small: 0.5rem;
$radius: 1rem;
$radius-large: 3rem;

$margin-small: 0.5rem;
$margin-medium: 0.75rem;
$margin-large: 1rem;

$font-size: 1rem;
$border-size: 1px;

$spacing-small: 0.5rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

// Media Query Sizing
$tablet-min-breakpoint: 768px;
$desktop-min-breakpoint: 1024px;
$tablet-max-breakpoint: calc(#{$desktop-min-breakpoint} - 1px);

// Font sizing
// Standard base
:root {
    font-size: 16px;
}

// Font sizes
$font-size-h1: 2.5rem;
$font-size-h2: 1.75rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1.125rem;
$font-size: 1rem;
$font-size-smallText: 0.85rem;
$font-size-microText: 0.5rem;

// Form sizes
$input-font-size: 1.125rem;
$input-min-height: 48px;
$button-min-height: $input-min-height;
$button-radius: 100px;
$button-horizontal-padding: 30px;
$input-min-height-small: 30px;
$section-max-width: 768px;

