@import "scss/base.scss";

.button {
  border-radius: $button-radius;
  cursor: pointer;
  font-size: $font-size;
  padding: 0 $button-horizontal-padding;
  appearance: none;
  min-height: $button-min-height;
  margin: $margin-medium;

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    background-color: $color-button-background;
    border-color: $color-button-background;
    color: $color-button-text;
    border: none;

    &:hover {
      background-color: rgba($color-button-background, 0.75);
      border-color: rgba($color-button-background, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background, 0.5);
      border-color: rgba($color-button-background, 0.5);

      &:hover {
        background-color: rgba($color-button-background, 0.25);
        border-color: rgba($color-button-background, 0.25);
      }
    }
  }

  &.secondary {
    color: $color-ollon-orange;
    background-color: $color-white;
    border-color: $color-button-background;

    &:hover {
      color: rgba($color-ollon-orange, 0.5);
      background-color: $color-white;
      border-color: rgba($color-button-background, 0.5);
    }

    &:disabled {
      background-color: rgba($color-button-background-disabled, 0.5);
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }
}

.linkButton {
  font-size: $font-size;
  padding: $padding-small $padding-medium;
  text-decoration-line: none; 
}