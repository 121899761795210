/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-ollon-orange: #F0793F;
$color-ollon-grey: #4A4C58;

$color-alert: #ff0000;
$color-success: #b1df95;
$color-white: #eaeaea;
$color-black: #242424;
$color-form-error: #D90038;

//  colour background
$color-background: #ffffff;
$color-background-secondary: #F0793F;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;

// colour text
$color-text: #787878;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;

// buttons
$color-button-background: $color-ollon-orange;
$color-button-text: $color-white;

$color-button-background-disabled: #c7c7c7;

// borders:
$color-border: #E5E5E5;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;
