@import "scss/base.scss";


.label {
 margin-right: $margin-medium;
} 

.required, .alert {
  color: $color-alert;   
}

.field {
  border: $border-size solid $color-border;
  height: $input-min-height;
  padding: 0 $padding-medium;
}