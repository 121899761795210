@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/grid.scss";
@import "./base/media-queries.scss";

code {
  white-space: pre-line;
  font-size: 85%;
}

li {
  margin-bottom: $margin-medium;
}

.codeContainer {
  background-color: $color-white;
  border-radius: 10px;
  padding: $padding-large;
}

.sideBySide {
  display: flex;
  .section {
    flex: 1;
    margin: $margin-small;
  }
}

.emphasize {
  font-weight: bold;
  color: $color-ollon-orange;
}

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

* {
  font-family: 'Avenir';
}


.outlineMode * { outline: 2px solid rgba(255,0,0,.6); :hover { outline: 5px solid rgba(255,0,0,0.6); } }
.outlineMode * * { outline: 2px solid rgba(0,255,0,.6); :hover { outline: 5px solid rgba(0,255,0,0.6); } }
.outlineMode * * * { outline: 2px solid rgba(0,0,255,.6); :hover { outline: 5px solid rgba(0,0,255,0.6); } }
.outlineMode * * * * { outline: 2px solid rgba(255,0,255,.6); :hover { outline: 5px solid rgba(255,0,255,0.6); } }
.outlineMode * * * * * { outline: 2px solid rgba(0,255,255,.6); :hover { outline: 5px solid rgba(0,255,255,0.6); } }
.outlineMode * * * * * * { outline: 2px solid rgba(255, 251, 4, 0.6); :hover { outline: 5px solid rgba(255,251,4,0.6); } }
